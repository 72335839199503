import { isExternalUrl } from '@flame-frontend-utils/commons-router6';
// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps } from 'react-router';
import { ButtonHTMLAttributes, ReactNode, RefObject, ForwardedRef } from 'react';
import { tw } from '../../styles/tw';

type ClickableProps<T = Record<never, never>> = (LinkProps | ButtonHTMLAttributes<HTMLButtonElement>) &
  T & {
    children?: ReactNode;
    isExternal?: boolean;
    clickableRef?: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>;
  };

const Clickable = (props: ClickableProps): JSX.Element => {
  if ('to' in props) {
    const { to, className, children, isExternal, clickableRef, ...anchorProps } = props;

    return isExternalUrl(to, isExternal) ? (
      <a
        ref={clickableRef as RefObject<HTMLAnchorElement>}
        href={to}
        target="_blank"
        /** @see https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c */
        rel="noopener noreferrer"
        {...anchorProps}
        className={tw(base, className)}
      >
        {children}
      </a>
    ) : (
      <Link {...props} className={tw(base, className)} ref={clickableRef as RefObject<HTMLAnchorElement>}>
        {children}
      </Link>
    );
  }

  const { className, children, clickableRef, ...buttonProps } = props;

  return (
    <button
      ref={clickableRef as RefObject<HTMLButtonElement>}
      type="button"
      {...buttonProps}
      className={tw(base, className)}
    >
      {children}
    </button>
  );
};

const base = tw(
  'inline-flex cursor-pointer items-center justify-center border-0 bg-transparent p-0 text-inherit no-underline antialiased [text-rendering:geometricPrecision] disabled:cursor-auto'
);

export { Clickable };
export type { ClickableProps };
