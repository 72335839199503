import { createContext, CSSProperties, forwardRef, PropsWithChildren, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { assertIsDefined, ScrollToTopOnMount } from '@flame-frontend-utils/commons';
import { Status } from '@flame-frontend-utils/commons-router6';
import { MAX_WIDTH, MIN_WIDTH } from '../../styles/width';
import { Advertisement, AdvertisementId } from '../Advertisement';
import { OpenGraph } from '../OpenGraph';
import { tw } from '../../styles/tw';
import { HEADER_HEIGHT } from '../../styles/fixedSizes';
import { ROUTES } from '../../lib/ROUTES';
import { Viewport } from './Viewport';

declare module 'react' {
  interface CSSProperties {
    '--page-background-color'?: string;
    '--page-minimal-width'?: string;
    '--page-overflow-y'?: string;
    '--main-max-width'?: string;
    '--header-height'?: string;
  }
}

interface PageProps {
  documentTitle?: string;
  documentDescription?: string;
  minimalWidth?: number;
  overflowY?: 'auto' | 'scroll';
  backgroundColor?: string;
  statusCode?: number;
  hideAdvertisement?: DisabledAds | { desktop?: DisabledAds; mobile?: DisabledAds };
  ogTitle?: string;
  ogType?: string;
  ogImage?: string;
  ogUrl?: string;
  ogDescription?: string;
  ogLocale?: string;
  twitterCard?: 'summary_large_image' | 'summary';
  /** @see https://developers.google.com/search/docs/crawling-indexing/consolidate-duplicate-urls#rel-canonical-link-method */
  canonicalUrl?: string;
  /** @see https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag */
  robotsMeta?: string;
  /** @see https://developers.google.com/search/docs/appearance/google-discover#optimize-your-web-pages-for-the-follow-feature */
  includeRssFeedLink?: boolean;
  desktopAdvertisementId?: AdvertisementId;
  mobileAdvertisementId?: AdvertisementId;
  hideHeader?: boolean;
  htmlClassName?: string;
  className?: string;
}

type DisabledAds = boolean | string[];

const Page = forwardRef<HTMLHtmlElement, PropsWithChildren<PageProps>>(
  (
    {
      className,
      minimalWidth = MIN_WIDTH,
      overflowY = 'auto',
      backgroundColor = 'rgb(var(--color-cream))',
      htmlClassName,
      documentTitle,
      documentDescription,
      statusCode,
      hideAdvertisement,
      ogTitle,
      ogType,
      ogImage,
      ogUrl,
      ogDescription,
      ogLocale,
      twitterCard,
      desktopAdvertisementId,
      mobileAdvertisementId,
      canonicalUrl,
      robotsMeta,
      includeRssFeedLink,
      hideHeader,
      children,
    },
    ref
  ) => {
    const [customTitle, setCustomTitle] = useState<string | null>(null);

    return (
      <Status code={statusCode || 200}>
        <ScrollToTopOnMount />
        <Helmet>
          <title>{customTitle || documentTitle || ogTitle || PUBLIC_CONFIG.APP_NAME}</title>
          <meta name="description" content={documentDescription || ogDescription || PUBLIC_CONFIG.APP_DESCRIPTION} />
          {canonicalUrl ? (
            <link rel="canonical" href={new URL(canonicalUrl, PUBLIC_CONFIG.CANONICAL_ROBOTS_HOST).toString()} />
          ) : null}
          {robotsMeta ? <meta name="robots" content={robotsMeta} /> : null}
          {includeRssFeedLink ? (
            <link
              rel="alternate"
              type="application/rss+xml"
              href={`${PUBLIC_CONFIG.CANONICAL_ROBOTS_HOST}${ROUTES.RSS.$buildPath({})}`}
            />
          ) : null}
          {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
          <html
            ref={ref}
            style={normalizeStyle({
              '--page-background-color': `${backgroundColor}`,
              '--page-minimal-width': `${minimalWidth}px`,
              '--page-overflow-y': overflowY,
              '--main-max-width': `${MAX_WIDTH / 16}rem`,
              '--header-height': `${hideHeader ? 0 : HEADER_HEIGHT / 16}rem`,
            })}
            className={tw(htmlClassName)}
          />
        </Helmet>
        <Viewport minimalWidth={minimalWidth} />
        <OpenGraph
          title={ogTitle || documentTitle || PUBLIC_CONFIG.APP_NAME}
          type={ogType}
          image={ogImage}
          url={ogUrl ?? canonicalUrl}
          description={ogDescription || documentDescription || PUBLIC_CONFIG.APP_DESCRIPTION}
          locale={ogLocale}
          card={twitterCard}
        />
        {!isAdHidden(hideAdvertisement, 'desktop', desktopAdvertisementId ?? AdvertisementId.DesktopTopBillboard) ? (
          <Advertisement
            id={desktopAdvertisementId ?? AdvertisementId.DesktopTopBillboard}
            className={tw('mx-auto my-0 hidden max-w-[var(--main-max-width)] md:flex')}
          />
        ) : null}
        {!isAdHidden(hideAdvertisement, 'mobile', mobileAdvertisementId ?? AdvertisementId.MobileTopBillboard) ? (
          <Advertisement
            id={mobileAdvertisementId ?? AdvertisementId.MobileTopBillboard}
            className={tw('mx-auto my-0 max-w-[var(--main-max-width)] md:hidden')}
          />
        ) : null}
        <SetCustomTitleContext.Provider value={setCustomTitle}>
          <main className={tw('flex grow flex-col items-stretch justify-center', className)}>{children}</main>
        </SetCustomTitleContext.Provider>
      </Status>
    );
  }
);

function isAdHidden(
  hideAdvertisement: PageProps['hideAdvertisement'],
  kind: 'mobile' | 'desktop',
  id: string
): boolean {
  return isRecord(hideAdvertisement) ? isAdDisabled(hideAdvertisement[kind], id) : isAdDisabled(hideAdvertisement, id);
}

function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

function isAdDisabled(disabledAds: DisabledAds | undefined, id: string): boolean {
  return disabledAds === true || (Array.isArray(disabledAds) && disabledAds.includes(id));
}

// Fix Helmet bug with SSR (based on SSR_MODE)
function normalizeStyle(style: CSSProperties): CSSProperties {
  if (!SSR_MODE) {
    /** @see https://github.com/nfl/react-helmet/issues/344 */
    return Object.entries(style)
      .map(([key, value]) => `${key}:${String(value)}`)
      .join(';') as unknown as CSSProperties;
  }

  return style;
}

const SetCustomTitleContext = createContext<((title: string | null) => void) | null>(null);

const useSetCustomTitle = (): ((title: string | null) => void) => {
  const setCustomTitle = useContext(SetCustomTitleContext);
  assertIsDefined(setCustomTitle, 'Looks like you are trying to use useSetCustomTitle outside of Page component.');

  return setCustomTitle;
};

export { Page, useSetCustomTitle };
export type { PageProps };
