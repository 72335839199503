import * as Sentry from '@sentry/react';
import { ErrorEvent } from '@sentry/types';
import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: PUBLIC_CONFIG.SENTRY_DSN,
  release: String(BUILD_TIME),
  environment: PUBLIC_CONFIG.APP_ENV,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['spletnik-frontend'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
  tracesSampleRate: PUBLIC_CONFIG.APP_ENV === 'production' ? 0.01 : 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: PUBLIC_CONFIG.APP_ENV === 'production' ? 0.1 : 0,
  ignoreErrors: [
    /** @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120 */
    'ResizeObserver loop limit exceeded',
    /** @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120 */
    'ResizeObserver loop completed with undelivered notifications.',
    // Player in ads
    'AbortError: The play() request was interrupted because video-only background media was paused to save power',
    'AbortError: The play() request was interrupted by a call to pause()',
    'NotSupportedError: Failed to load because no supported source was found.',
    'NotSupportedError: The element has no supported sources.',
  ],
  beforeSend(event) {
    if (!isXhrEvent(event) && !isNetworkConnectivityError(event)) {
      return event;
    }

    // Most likely ad-related, but makes sense to keep watching
    const SAMPLED_ERRORS = [
      'Non-Error promise rejection captured with value: undefined',
      'AbortError: The operation was aborted.',
    ];
    const SAMPLING_RATE = 0.1;

    const shouldSample = SAMPLED_ERRORS.some((pattern) => event.message?.includes(pattern));

    if (shouldSample && Math.random() <= SAMPLING_RATE) {
      return event;
    }

    return null;
  },
});

function isXhrEvent(event: ErrorEvent) {
  try {
    // eslint-disable-next-line no-underscore-dangle
    const rawEventData = event.extra?.__serialized__;

    return (
      isRecord(rawEventData) &&
      typeof rawEventData.target === 'string' &&
      rawEventData.target.includes('XMLHttpRequest')
    );
  } catch {
    // Fallback to false
  }

  return false;
}

function isNetworkConnectivityError(event: ErrorEvent) {
  try {
    const value = event.exception?.values?.[0]?.value;

    return value?.includes('Failed to fetch') || value?.includes('Load failed');
  } catch {
    // Fallback to false
  }

  return false;
}

function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}
