import { useMatch } from 'react-router';
import { Button, ButtonVariant } from '../Button';
import Magnifier from './icons/magnifier.svg?svgr';
import Burger from './icons/burger.svg?svgr';
import { ROUTES } from '../../lib/ROUTES';
import { useOnPostsNavigation } from '../../react-hooks/useOnPostsNavigation';
import { Stream } from '../../lib/graphql.document';
import { CreatePostButton } from '../CreatePostButton';
import { NotificationIcon } from './NotificationIcon';
import { useLoginInfo } from '../../react-hooks/useLoginInfo';

interface Props {
  onMenuClick: () => void;
  onNotificationsClick: (hasUnviewed: boolean) => void;
}

const Controls = ({ onMenuClick, onNotificationsClick }: Props): JSX.Element => {
  const { user } = useLoginInfo();
  const onPostsNavigation = useOnPostsNavigation();

  const isOnBlogs = Boolean(useMatch(ROUTES.BLOGS.$path()));

  return (
    <div className="flex items-center gap-4.5">
      {isOnBlogs ? (
        <CreatePostButton className="hidden md:inline-flex" variant={ButtonVariant.Outlined} buttonAlwaysVisible />
      ) : null}
      <Button onClick={() => onPostsNavigation(Stream.Blog)} to={ROUTES.BLOGS.$buildPath({})}>
        Блоги
      </Button>
      {user ? <NotificationIcon onClick={onNotificationsClick} user={user} /> : null}
      <Button to={ROUTES.SEARCH.$buildPath({})} aria-label="Поиск">
        <Magnifier />
      </Button>
      <Button onClick={onMenuClick} aria-label="Меню">
        <Burger />
      </Button>
    </div>
  );
};

export { Controls };
