import { useEffect } from 'react';
import { useLocation } from 'react-router';

declare global {
  interface Window {
    ym?: (id: number, type: 'hit', url: string, data?: { title?: string; referer?: string }) => void;
  }
}

const MetrikaPageViewReporter = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    sendPageViewToMetrika();
  }, [pathname]);

  return null;
};

function sendPageViewToMetrika() {
  if (PUBLIC_CONFIG.METRIKA_ID) {
    const url = `${window.location.pathname}${window.location.search}${window.location.hash}`;

    window.ym?.(PUBLIC_CONFIG.METRIKA_ID, 'hit', url, {
      title: document.title,
    });
  }
}

export { MetrikaPageViewReporter, sendPageViewToMetrika };
