import { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Outlet, Route } from 'react-router';
import {
  ColorScheme,
  ColorSchemeProvider,
  ColorSchemeVariant,
  HydrateStatusRefProvider,
  ImageServiceClient,
  ImageServiceProvider,
} from '@flame-frontend-utils/commons';
import {
  Navigate,
  PostMessagePage,
  RouterContextProvider,
  StaticRouterContext,
} from '@flame-frontend-utils/commons-router6';
import { ErrorBoundary, withProfiler } from '@sentry/react';
import './style.css';
import './lib/dev-log';
import { RenderErrorPage } from './pages/RenderErrorPage';
import { ROUTES, ShowcaseComponent } from './lib/ROUTES';
import { Routes } from './components/Routes';
import { MainPage } from './pages/MainPage';
import { Layout } from './components/Layout';
import { ComponentShowcasePage } from './pages/ComponentShowcasePage';
import { SeededRandomProvider } from './components/SeededRandomizer';
import { BlogsPage } from './pages/BlogsPage';
import { DARK_THEME_COLOR, LIGHT_THEME_COLOR } from './styles/colors';
import { PostPage } from './pages/PostPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { SearchPage } from './pages/SearchPage';
import { NavigateFromLegacyTags } from './components/NavigateFromLegacyTags';
import { AdContext, AdvertisementHead } from './components/Advertisement';
import { InfoPage, InfoType } from './pages/InfoPage';
import { StaticHelmet } from './components/StaticHelmet';
import { BlogEditPage } from './pages/BlogEditPage';
import { LoginFormProvider } from './components/LoginFormProvider';
import { SettingsFormProvider } from './components/SettingsFormProvider';
import { SentryUserSetter } from './components/SentryUserSetter';
import { TagPage } from './pages/TagPage';
import { UserPage } from './pages/UserPage';
import { Toast } from './components/Toast';
import { MetrikaPageViewReporter } from './components/MetrikaPageViewReporter';
import { ApolloProvider } from './components/ApolloProvider';
import { ModerationPopupProvider } from './components/ModerationPopupProvider';
import { AdPageChangeHandler } from './components/AdPageChangeHandler';
import { ServerDataProvider } from './components/ServerDataProvider';

interface AppProps {
  client: ApolloClient<NormalizedCacheObject>;
  serverData: typeof window.SERVER_DATA;
  helmetContext?: Record<string, unknown>;
  routerContext?: StaticRouterContext;
  adContext?: AdContext;
}

const App: FC<AppProps> = ({ client, helmetContext, routerContext, adContext, serverData }) => (
  <HelmetProvider context={helmetContext}>
    <ApolloProvider client={client}>
      <ImageServiceProvider client={new ImageServiceClient({ baseUrl: PUBLIC_CONFIG.IMAGE_SERVICE_ENDPOINT })}>
        <RouterContextProvider context={routerContext}>
          <ServerDataProvider data={serverData}>
            <SeededRandomProvider>
              <ErrorBoundary fallback={(error) => <RenderErrorPage error={error.error} />}>
                <HydrateStatusRefProvider>
                  <Toast />
                  <ColorSchemeProvider
                    Helmet={Helmet}
                    darkThemeColor={DARK_THEME_COLOR}
                    lightThemeColor={LIGHT_THEME_COLOR}
                    defaultColorScheme={ColorScheme.Light}
                    defaultColorSchemeVariant={ColorSchemeVariant.Secondary}
                  >
                    <SettingsFormProvider>
                      <LoginFormProvider>
                        <ModerationPopupProvider>
                          <StaticHelmet />
                          <AdvertisementHead adContext={adContext} />
                          <SentryUserSetter />
                          <MetrikaPageViewReporter />
                          <AdPageChangeHandler />
                          <Routes>
                            <Route element={<Layout hideHeader />}>
                              <Route path={ROUTES.BLOG_EDIT.$path()} element={<BlogEditPage />} />
                              <Route
                                path={ROUTES.POST_MESSAGE.$path()}
                                element={<PostMessagePage fallbackTo={ROUTES.ROOT.$buildPath({})} />}
                              />
                            </Route>
                            <Route element={<Layout />}>
                              <Route
                                path={ROUTES.ROOT.$path()}
                                element={
                                  <>
                                    <MainPage />
                                    <Outlet />
                                  </>
                                }
                              >
                                <Route
                                  path={ROUTES.ROOT.LEGACY_PAGE.$path()}
                                  element={<Navigate to=".." replace statusCode={301} />}
                                />
                              </Route>
                              <Route path={ROUTES.BLOGS.$path()} element={<BlogsPage />} />
                              <Route
                                path={ROUTES.POST.$path()}
                                element={
                                  <NavigateFromLegacyTags>
                                    <PostPage />
                                  </NavigateFromLegacyTags>
                                }
                              >
                                <Route path={ROUTES.POST.$.LEGACY_COMMENTS.$path({ relative: true })} element={null} />
                              </Route>
                              <Route path={ROUTES.USER_FILTER.$path()} element={<UserPage />} />
                              <Route
                                path={ROUTES.TAG_FILTER.$path()}
                                element={
                                  <>
                                    <TagPage />
                                    <Outlet />
                                  </>
                                }
                              >
                                <Route
                                  path={ROUTES.TAG_FILTER.LEGACY_PAGE.$path()}
                                  element={<Navigate to=".." replace statusCode={301} />}
                                />
                              </Route>
                              <Route
                                path={ROUTES.LEGACY_TAG.$path()}
                                element={<Navigate to={ROUTES.SEARCH.$buildPath({})} replace statusCode={301} />}
                              />
                              <Route path={ROUTES.SEARCH.$path()} element={<SearchPage />} />
                              <Route path={ROUTES.ABOUT.$path()} element={<InfoPage type={InfoType.About} />} />
                              <Route path={ROUTES.PRIVACY.$path()} element={<InfoPage type={InfoType.Privacy} />} />
                              <Route path={ROUTES.AGREEMENT.$path()} element={<InfoPage type={InfoType.Agreement} />} />
                              <Route
                                path={ROUTES.MODERATION_RULES.$path()}
                                element={<InfoPage type={InfoType.ModerationRules} />}
                              />
                              <Route
                                path={ROUTES.ADVERTISING.$path()}
                                element={<InfoPage type={InfoType.Advertising} />}
                              />
                              <Route
                                path={ROUTES.JOB_OPENINGS.$path()}
                                element={<InfoPage type={InfoType.JobOpenings} />}
                              />
                              {[
                                ROUTES.LEGACY_RSS,
                                ROUTES.LEGACY_RSS_FEED,
                                ROUTES.LEGACY_RSS_YANDEX,
                                ROUTES.LEGACY_RSS_MIRTESEN,
                                ROUTES.LEGACY_RSS_MAILRU,
                                ROUTES.LEGACY_RSS_ANEWS,
                              ].map(({ $path }) => (
                                <Route
                                  key={$path()}
                                  path={$path()}
                                  element={<Navigate to={ROUTES.RSS.$buildPath({})} replace statusCode={301} />}
                                />
                              ))}
                              <Route path={ROUTES.NOT_FOUND.$path()} element={<NotFoundPage />} />
                            </Route>
                            {PUBLIC_CONFIG.APP_ENV === 'development' ? (
                              <Route path={ROUTES.COMPONENT_SHOWCASE.$path()}>
                                <Route
                                  path={ROUTES.COMPONENT_SHOWCASE.COMPONENT.$path()}
                                  element={<ComponentShowcasePage />}
                                />
                                <Route
                                  index
                                  element={
                                    <Navigate
                                      to={ROUTES.COMPONENT_SHOWCASE.COMPONENT.$buildPath({
                                        params: {
                                          component: ShowcaseComponent.Clickable,
                                        },
                                      })}
                                    />
                                  }
                                />
                              </Route>
                            ) : null}
                          </Routes>
                        </ModerationPopupProvider>
                      </LoginFormProvider>
                    </SettingsFormProvider>
                  </ColorSchemeProvider>
                </HydrateStatusRefProvider>
              </ErrorBoundary>
            </SeededRandomProvider>
          </ServerDataProvider>
        </RouterContextProvider>
      </ImageServiceProvider>
    </ApolloProvider>
  </HelmetProvider>
);

const DecoratedApp = SSR_MODE ? App : withProfiler(App);

export { DecoratedApp as App };
