import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  RequestHandler,
  split,
} from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { onError } from '@apollo/client/link/error';
import { sha256 } from 'crypto-hash';
import { dedupeFragmentsLink } from '@flame-frontend-utils/commons-apollo';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { globalErrorHandler } from './apollo-errors';
import { ApolloContext } from './ApolloContext';
import { apolloNetworkStatusLink } from './apollo-network-status-notifier';
import { cacheConfig } from './cacheConfig';
import { typeDefs } from './typeDefs.document';
import { retryLink } from './retryLink';

export interface ApolloClientOptions {
  fetch?: typeof fetch;
  context?: ApolloContext;
  headers?: Record<string, string>;
}

export function getApolloClient({
  fetch,
  context,
  headers,
}: ApolloClientOptions = {}): ApolloClient<NormalizedCacheObject> {
  const cache = new InMemoryCache(cacheConfig);

  if (!SSR_MODE) {
    cache.restore(window.APOLLO_STATE);
  }

  const httpLink = new HttpLink({
    uri: SSR_MODE ? PUBLIC_CONFIG.SSR_GRAPHQL_ENDPOINT : PUBLIC_CONFIG.GRAPHQL_ENDPOINT,
    fetch,
    credentials: 'include',
    headers,
  });

  const splitLink = SSR_MODE
    ? httpLink
    : split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        new GraphQLWsLink(
          createClient({
            url: PUBLIC_CONFIG.GRAPHQL_SUBSCRIPTIONS_ENDPOINT,
          })
        ),
        httpLink
      );

  return new ApolloClient({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
      },
    },
    ssrMode: SSR_MODE,
    cache,
    typeDefs,
    link: from(
      [
        dedupeFragmentsLink(),
        !SSR_MODE ? apolloNetworkStatusLink : undefined,
        onError(globalErrorHandler({ context })),
        PUBLIC_CONFIG.APP_ENV === 'production' && !SSR_MODE ? createPersistedQueryLink({ sha256 }) : undefined,
        retryLink,
        splitLink,
      ].filter(Boolean) as (ApolloLink | RequestHandler)[]
    ),
  });
}
