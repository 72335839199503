import { Reference, useMutation } from '@apollo/client';
import { ChangeEvent, FormEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { assertIsDefined, hoverable, useMediaQuery } from '@flame-frontend-utils/commons';
import { AddCommentDocument } from './gql/AddComment.document';
import {
  Comment,
  CommentFilterInput,
  CommentOrderInput,
  CreateCommentInput,
  Pagination,
} from '../../../lib/graphql.document';
import { useLoginInfo } from '../../../react-hooks/useLoginInfo';
import { useOnLoginFormOpen } from '../../LoginFormProvider';
import { CommentWithChildrenFragmentFragmentDoc } from '../../../gql/CommentWithChildrenFragment.document';
import { useOnSettingsFormOpen } from '../../SettingsFormProvider';
import { ignoreReject } from '../../../lib/ignoreReject';

interface UseSubmitParams {
  slug: string;
  postId: string;
  responseTo: string;
  renderingId?: string;
  receiverId?: string;
  onComplete?: () => void;
}

export const useNewComment = ({ postId, slug, responseTo, renderingId, receiverId, onComplete }: UseSubmitParams) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [value, setValue] = useState('');
  const [addComment, { loading }] = useMutation(AddCommentDocument, {
    update(cache, { data }, { variables }) {
      assertIsDefined(variables);
      assertIsDefined(data?.createComment);

      const newCommentRef = cache.writeFragment({
        data: data.createComment,
        fragment: CommentWithChildrenFragmentFragmentDoc,
        fragmentName: 'CommentWithChildrenFragment',
      });

      if (renderingId) {
        cache.modify<Comment>({
          id: cache.identify({ id: renderingId, __typename: 'Comment' }),
          fields: {
            children(cachedChildren) {
              return [...(cachedChildren ?? []), ...(newCommentRef ? [newCommentRef] : [])];
            },
          },
        });
      } else {
        cache.modify<{
          comments: {
            items: Record<string, Reference>;
          };
        }>({
          fields: {
            comments(cachedComments, { storeFieldName }) {
              /**
               * StoreFieldName is a string, which looks like this:
               * comments:{"filter":{"postIdentity":"d3c488ce-23ed-46d6-a3f2-15eb9ee7215b"},"order":{"direction":"DESC","field":"CREATED_AT"}}
               */
              const queryParams = JSON.parse(storeFieldName.split('comments:')[1] || '') as {
                filter: CommentFilterInput;
                order: CommentOrderInput;
                pagination: Pagination;
              };

              const { postIdentity } = queryParams.filter;
              if (postIdentity === slug && 'items' in cachedComments) {
                return {
                  ...cachedComments,
                  items: {
                    ...(newCommentRef ? { [data.createComment.id]: newCommentRef } : {}),
                    ...cachedComments.items,
                  },
                };
              }
              return cachedComments;
            },
          },
        });
      }
    },
  });

  const { data: userData } = useLoginInfo();
  const onLoginFormOpen = useOnLoginFormOpen();
  const onSettingsFormOpen = useOnSettingsFormOpen();

  const trimmedValue = value.trim();
  const canSubmit = trimmedValue.length > 0 && !loading && userData;

  const needUser = !userData;
  const needLogin = !userData?.loginInfo.user;
  const needNickname = !userData?.loginInfo.user?.nickname;

  const onChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }, []);

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      assertIsDefined(userData);

      if (userData.loginInfo.user) {
        const dto: CreateCommentInput = {
          postId,
          replyToId: receiverId,
          body: value,
        };

        const response = await ignoreReject(addComment({ variables: { dto } }));

        if (response) {
          setValue('');

          onComplete?.();
        }
      } else {
        onLoginFormOpen();
      }
    },
    [addComment, onComplete, onLoginFormOpen, postId, receiverId, userData, value]
  );

  const onLogin = useCallback(() => {
    onLoginFormOpen();
  }, [onLoginFormOpen]);

  const isHoverable = useMediaQuery(hoverable, { initialValue: false });

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isHoverable && e.key === 'Enter' && !e.shiftKey) {
        if (canSubmit) {
          void onSubmit(e);
        } else {
          e.preventDefault();
        }
      }
    },
    [canSubmit, isHoverable, onSubmit]
  );

  useEffect(() => {
    if (responseTo) {
      const textarea = formRef.current?.getElementsByTagName('textarea')[0];
      textarea?.focus();
    }
  }, [responseTo]);

  return {
    value,
    formRef,
    loading,
    canSubmit,
    needUser,
    needLogin,
    needNickname,
    onSubmit,
    onLogin,
    onSettingsFormOpen,
    onChange,
    onKeyDown,
  };
};
