import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { CommentAuthor, UniversalComment } from '../../comments';
import { TimeAgo } from '../../TimeAgo';
import { Button } from '../../Button';
import { ViewNotificationDocument } from './gql/ViewNotification.document';
import { ROUTES } from '../../../lib/ROUTES';
import { NotificationsDocument } from './gql/Notifications.document';
import { tw } from '../../../styles/tw';
import { NotificationPostFragment } from './gql/NotificationPost.document';
import { ignoreReject } from '../../../lib/ignoreReject';
import { appendSearchParams } from '../../../lib/appendSearchParams';

interface CommentProps {
  notificationId: string;
  isViewed: boolean;
  body: string;
  createdAt: Date;
  comment?: UniversalComment;
  post?: NotificationPostFragment;
  onClose: () => void;
}

const Notification = ({ notificationId, isViewed, comment, post, body, createdAt, onClose }: CommentProps) => {
  const [viewNotification] = useMutation(ViewNotificationDocument, {
    variables: { id: notificationId },
    refetchQueries: [NotificationsDocument],
  });
  const navigate = useNavigate();

  const onViewClick = useCallback(async () => {
    if (!isViewed) {
      void ignoreReject(viewNotification());
    }

    if (comment) {
      await navigate(
        appendSearchParams(
          ROUTES.POST.$buildPath({
            params: { slug: comment.post.slug },
            searchParams: { parentCommentId: comment.parent?.id, targetCommentId: comment.id },
          }),
          comment.post.searchParams
        )
      );
    } else if (post) {
      await navigate(appendSearchParams(ROUTES.POST.$buildPath({ params: { slug: post.slug } }), post.searchParams));
    }

    onClose();
  }, [comment, isViewed, navigate, onClose, post, viewNotification]);

  return (
    <div className="mt-5 border-b border-blood pb-5">
      <div className="mb-5 flex items-center justify-between">
        <CommentAuthor author={comment?.author} onClick={onClose} />
        <TimeAgo className={tw('shrink-0 whitespace-nowrap text-sm')} time={new Date(createdAt)} />
      </div>
      <div className="overflow-anywhere mb-6 whitespace-pre-wrap">
        <p>{body}</p>
      </div>
      {comment || post ? (
        <Button className="text-xs" onClick={onViewClick}>
          Перейти в материал
        </Button>
      ) : null}
    </div>
  );
};

export { Notification };
